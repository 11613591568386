import { Box, Container, Flex, Grid, Text } from "@chakra-ui/react";
import Carousel from "../Carousel/Carousel";

const Depositions = () => {
  return (
    <Box>
      <Carousel />
    </Box>
  );
};

export default Depositions;
